import React, { useState } from 'react';

import styled from 'styled-components';

import { Text } from 'components';

import { tablet, useQuery } from 'styles/breakpoints';
import HomePageIcon from 'assets/images/starter/2.1.png';
import HomePageIcon2 from 'assets/images/starter/2.2.png';
import FactorIcon from 'assets/images/starter/3.1.png';
import FactorIcon2 from 'assets/images/starter/3.2.png';
import SymptomsIcon from 'assets/images/starter/4.1.png';
import SymptomsIcon2 from 'assets/images/starter/4.2.png';
import SymptomsIcon3 from 'assets/images/starter/4.3.png';
import SymptomsIcon4 from 'assets/images/starter/4.4.png';
import AnalyzeIcon from 'assets/images/starter/5.1.png';
import AnalyzeIcon2 from 'assets/images/starter/5.2.png';
import BuildHabits1 from 'assets/images/starter/6.1.png';
import BuildHabits2 from 'assets/images/starter/6.2.png';
import KeepDataIcon from 'assets/images/starter/7.1.png';

import theme from 'utils/theme';
import Layout from 'layouts/Layout';

const SingleMobile = () => (
  <Layout isMobileLink>
    <Container>
      <Title>Getting to know Lasting Change | Tips for a smooth start</Title>
      <Heading>
        1. Read our starter guide on how to use the Lasting Change app
      </Heading>

      <StyledText>
        We understand that starting something new can be overwhelming but
        don&apos;t worry. Here are several tips for a simple and effective start
        with Lasting Change, which was just rated as the No. 1 chronic health
        conditions tracker and management app in a systematic review of a
        peer-reviewed scientific journal.
      </StyledText>
      <StyledText>
        We all know that adopting new habits takes time. Start slowly! While you
        can track anything with Lasting Change, we highly recommend tracking
        only a few things in the beginning. Choose only the most important
        factors and stick to them. Seeing the benefits of tracking a few factors
        will give you the motivation to build new habits and your new healthier
        routine!
      </StyledText>
      <StyledText>
        All of the widgets let you make multiple logs at different times of the
        day or you can make all your logs at once. And remember, the more data
        you enter, the better and more accurate insights you will get!
      </StyledText>
      <Heading>2. Welcome! Set up your Homepages</Heading>
      <StyledText>
        You can track pretty much anything with Lasting Change. Even though
        you&apos;ve customized your homepage while setting up your account, you
        should definitely reorganize it once you get the hang of it and identify
        the most important things you will track.
      </StyledText>
      <StyledText>
        Simply <TextBold>click</TextBold> on your{' '}
        <TextBold>profile icon</TextBold> at the top right of your homepage, go
        to <TextBold>Settings → App preferences → Customize Homepage</TextBold>,
        and build a page that brings the most value.
      </StyledText>
      <ImageContainer>
        <ImageComp src={HomePageIcon} />
        <ImageComp src={HomePageIcon2} />
      </ImageContainer>
      <Heading>3. What&apos;s a Factor?</Heading>
      <StyledText>
        For every little thing or detail you can track, we use one simple word
        that connects everything - <TextBold>Factor</TextBold>. This could mean
        a morning ritual, a meetup with friends, a party, today&apos;s weather,
        places you visit, hobbies. Whatever you can think of. Anything. You can
        even add a photo, icon, or emoji to the factor making it more personal
        and fun. Your most commonly logged factors appear on the homepage and
        less frequent ones can be found if you expand the widget.
      </StyledText>
      <StyledText>
        The <TextBold>Factors</TextBold> can be fully customized. Simply click
        the <TextBold>expand button</TextBold> to expand the{' '}
        <TextBold>Factors</TextBold> widget, and make it personal: add any new
        Factor you can think of, edit Factors&apos; categories, or create new
        customized Factors&apos; categories yourself. Once created, you can
        always find your custom Factors in this section and select them with a
        simple click.
      </StyledText>
      <ImageContainer>
        <ImageComp src={FactorIcon} />
        <ImageComp src={FactorIcon2} />
      </ImageContainer>
      <Heading>
        4. Unique symptoms, medications & supplements for every health condition
      </Heading>
      <StyledText>
        We understand that everyone is different and has their own needs.
        That&apos;s why you can track{' '}
        <TextBold>Symptoms, Medications & Supplements</TextBold> that suit your
        health goals the best. Health is always a priority, therefore, we
        recommend spending some extra minutes customizing these sections at the
        start.
      </StyledText>
      <SubHeading>4.1. Creating your symptoms</SubHeading>
      <StyledText>
        To edit your Symptoms, click the expand button in this section and press
        the edit icon. Symptoms are by default added to Mental or Physical
        categories, but you can always create your own. Once you&apos;re done
        with that, you&apos;ll be able to rate your symptom&apos;s severity from
        your homepage with just a couple of clicks.
      </StyledText>

      <ItalicText>
        <ItalicBold>Tip:</ItalicBold> Don&apos;t forget, for the days that you
        don&apos;t experience a symptom, mark its severity as 0 to create more
        accurate insights.
        <br />
        <br />
      </ItalicText>

      <ImageContainer>
        <ImageComp src={SymptomsIcon} />
        <ImageComp src={SymptomsIcon2} />
      </ImageContainer>

      <SubHeading>4.2. Managing your medications & supplements</SubHeading>
      <StyledText>
        To edit your Medications & Supplements, click the expand button in this
        section and add new meds or supplements. You can schedule doses and
        reminders for every medication and Lasting Change will send you
        notifications so you wouldn&apos;t miss one. Once you set it up, you can
        log your doses straight from the homepage. If you forgot to mark your
        dose on time, you can do it later and specify the exact time you took
        it.
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip:</ItalicBold> Don&apos;t forget, a missed dose is
        equally important! Mark every taken or missed dose to create more
        accurate insights.
        <br />
        <br />
      </ItalicText>
      <ImageContainer>
        <ImageComp src={SymptomsIcon3} />
        <ImageComp src={SymptomsIcon4} />
      </ImageContainer>
      <Heading>
        5. Analyze what impacts your health on the Insights page
      </Heading>
      <StyledText>
        To access your insights and statistics, you can click on the statistics
        icon on your homepage at the top right of each of the widgets or you can
        simply find them in the Insights screen where you can select which
        things and for which period you want to see. Here you will be able to
        see your average ratings, counts, trends and changes between different
        periods, correlations, see your medication reports, or what effect it
        had on your symptoms, and see how different factors affect your
        symptoms, mood, sleep, or anything else you wish to analyze.
      </StyledText>
      <MarginBottom>
        <ItalicText>
          <ItalicBold>Tip:</ItalicBold>
          To start seeing Effects you must have 3 days with a factor, for
          example, &quot;Gym&quot;, and 3 days when that factor did not occur.
        </ItalicText>
      </MarginBottom>

      <StyledText>
        Using the Lasting Change app for a week consistently is sure to give you
        actionable Insights. Let&apos;s say you want to find out how to improve
        your mood. Choose &quot;Mood&quot; from the dropdown menu at the top and
        discover a snapshot of what your mood was like recently. Take a look at
        the &quot;Effect on Mood&quot; widget and see that &quot;Work from
        home&quot; and &quot;Busy day&quot; had the best effect. Try to have
        more days like these next week and see if it works!
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip:</ItalicBold> Remember - the more days you&apos;ll
        track, the more accurate insights will be.
        <br />
        <br />
      </ItalicText>
      <ImageContainer>
        <ImageComp src={AnalyzeIcon} />
        <ImageComp src={AnalyzeIcon2} />
      </ImageContainer>
      <Heading>
        6. Build good habits and break bad ones with our tailored Experiments
      </Heading>
      <StyledText>
        What we did not mention until now are the Experiments.{' '}
        <ItalicBold>Experiments.</ItalicBold> We created this section to help
        you get started on building good habits the easy way. We have crafted
        different experiments to address various parts of your life.{' '}
      </StyledText>

      <StyledText>
        All you need to do is go to the Experiments page in the app and choose
        an <ItalicBold>experiment</ItalicBold> you’d like to try out. Once
        you’ll choose one of the experiments, you’ll be able to read a little
        bit more about the new habit, see the benefits it could give you, and
        what you’ll need to be doing if you’ll decide to start it.{' '}
      </StyledText>

      <StyledText>
        We really hope that these experiments will help you improve your mood,
        productivity, overcome the issues in your life, and increase your
        well-being and overall performance in every area of your life.
        Hopefully, it will help you make the good habits stick for the long term
        and bad habits disappear in no time.
        <ItalicBold>Remember, habits really shape our lives!</ItalicBold>
      </StyledText>
      <ImageContainer>
        <ImageComp src={BuildHabits1} />
        <ImageComp src={BuildHabits2} />
      </ImageContainer>
      <Heading>
        7. Keep all data in one place! Sync with Google Fit or Apple Health
      </Heading>
      <StyledText>
        By syncing Lasting Change with your phone&apos;s health app, you can
        automatically track your Steps, Sleep, Weight, Heart Rate, Blood
        Pressure, Blood Glucose, and Water intake. If you haven&apos;t already
        synced, simply click on your profile icon at the top right of your
        homepage to reach{' '}
        <ItalicBold>
          Settings → App preferences → Connected Health Apps
        </ItalicBold>{' '}
        and turn on <ItalicBold>syncing.</ItalicBold>
      </StyledText>
      <StyledText>
        Keep in mind that syncing is not the only option. You can always make
        manual entries of Health Measurements by yourself.
      </StyledText>
      <MarginBottom>
        <ItalicText>
          <ItalicBold>Tip: </ItalicBold> You can even log multiple health
          metrics entries throughout the day, for example, your naps.
        </ItalicText>
      </MarginBottom>

      <ImageCompFullWidth src={KeepDataIcon} />

      <StyledText>
        Still have questions about Lasting Change? Let us know how we can help
        you. Reach out to us on FB, IG, Reddit, Twitter, or just write us an
        email at{' '}
        <EmailLink href="mailto:hello@thelastingchange.com">
          hello@thelastingchange.com.
        </EmailLink>
      </StyledText>
    </Container>
  </Layout>
);

export default SingleMobile;

const EmailLink = styled.a`
  color: ${theme.colors.deepPurple}!important;
  font-weight: 400;
  cursor: pointer;
`;

const ImageComp = styled.img`
  background-size: cover;
  border-radius: 12px;
  height: 280px;
  object-fit: cover;
  flex-grow: 1;
  max-width: 45%;
  @media ${tablet} {
    height: 210px;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const ImageCompFullWidth = styled(ImageComp)`
  max-width: 100%;
  margin-bottom: 2rem;
`;
const ImageContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  margin: auto;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 24px;
  text-transform: capitalize;
  font-size: 48px;
  font-weight: 700;
  color: #222;
  letter-spacing: -0.036em;
  line-height: 140%;
  font-family: Inter;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const SubHeading = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 26px 0 22px;
  font-family: Inter;
  color: ${theme.colors.deepPurple};
`;

const Heading = styled(Text)`
  font-size: 28px;
  font-weight: 600;
  margin: 26px 0 22px;
  color: ${theme.colors.deepPurple};
  letter-spacing: -0.036em;
  line-height: 140%;
  font-family: Inter;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 22px;
  font-family: Inter;
  font-weight: 300;
`;

const ItalicText = styled(StyledText)`
  margin-bottom: 22px;

  display: flex;
  font-style: italic;
  display: inline;
`;

const TextBold = styled(StyledText)`
  font-weight: 500;
  display: inline;
`;

const ItalicBold = styled(ItalicText)`
  margin-bottom: 22px;
  font-weight: 500;
`;

const MarginBottom = styled.div`
  margin-bottom: 22px;
`;
